<template>
  <div>
    <c-search-box @enter="getMapData" class="no-margin">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 사업장 -->
          <c-plant type="none" name="plantCd" v-model="searchParam.plantCd"/>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 승인년도 -->
          <c-datepicker
            type="year"
            default="today"
            label="LBLYEAR"
            name="permitYear"
            v-model="searchParam.permitYear"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 신청부서 -->
          <c-dept type="search" label="LBL0000202" name="applicationDeptCd" v-model="searchParam.applicationDeptCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 발급부서 -->
          <c-dept type="search" label="LBL0000203" name="issuedDeptCd" v-model="searchParam.issuedDeptCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 진행단계 -->
          <c-select
            type="search"
            stepperGrpCd="SWP_STEP_CD"
            itemText="stepperMstNm"
            itemValue="stepperMstCd"
            name="swpStepCd"
            label="LBLSTEPPROGRESS"
            v-model="searchParam.swpStepCd"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <!-- 작업허가 현황 -->
    <c-card title="LBL0000020" class="cardClassDetailForm" :noMarginPadding="true">
      <template slot="card-button">
        <q-btn-group outline>
          <c-btn label="LBLSEARCH" icon="search" @btnClicked="getMapData" />
        </q-btn-group>
      </template>
      <template slot="card-detail">
        <template>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 container" ref="carousel">
          <q-carousel
            v-if="mapObj.maps && mapObj.maps.length > 0"
            class="map-carousel"
            swipeable
            animated
            arrows
            control-type="regular"
            control-color="grey-6"
            v-model="mapObj.slide"
            infinite
            @transition="transition"
          >
            <q-carousel-slide 
              v-for="(map, idx) in mapObj.maps"
              :key="idx"
              :name="idx" 
              :img-src="map.mapSrc">
              <template>
                <div class="mapTitleDiv" v-text="map.mapName"></div>
                  <VueDraggableResizable
                    v-for="(item, idx) in map.maps"
                    :key="idx"
                    ref="markImage"
                    class="mainMarkImage"
                    :resizable="false"
                    :parent="true"
                    :draggable="false"
                    :x="item.locationXcoordinate * mapRate"
                    :y="item.locationYcoordinate * mapRate"
                    :w="40 " :h="40"
                    :grid="[20, 20]"
                  >
                    <q-icon name="push_pin" class="blinking" />
                    <div class="markImage-abbreviation-parent"
                      :style="{ 
                        width: '220px',
                        left: getLeft(item),
                        top: getTop(item),
                      }">
                      <div class="markImage-abbreviation">
                        <transition name="mark-list" tag="div">
                          <!-- <div v-show="item.isHover"> -->
                          <div v-show="true">
                            <q-banner v-for="(resultType, idx) in item.resultTypes"
                              :key="idx"
                              dense 
                              :class="[idx % 2 ===1 ? 'bg-grey-3' : 'bg-grey-1', 'markImage-abbreviation-banner-detail']">
                              <template v-slot:avatar>
                                <q-icon :name="workPermitIconName(resultType)" class="text-red" />
                              </template>
                              {{resultType.sopWorkTypeName}}
                              <q-badge :color="workPermitbadgeColor(resultType.swpStepName)" :label="resultType.swpStepName" />
                              <q-btn class="q-ml-sm" outline round dense color="red" size="9px" label="" icon="add" @click="openWorkPermit(resultType)" />
                              <div class="caption-work">
                                {{resultType.workSummary}}
                              </div>
                            </q-banner>
                          </div>
                        </transition>
                      </div>
                    </div>
                  </VueDraggableResizable>
              </template>
            </q-carousel-slide>
          </q-carousel>
          <!-- 등록된 지도가 없습니다. -->
          <el-empty v-else 
            :image-size="435"
            :description="$comm.getLangMessage('MSG0000137')"></el-empty>
        </div>
        <!-- 작업허가서 목록 -->
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 container">
          <c-table
            ref="table"
            title="LBL0000210"
            :isTitle="false"
            :columns="grid.columns"
            :data="tempMapDetails"
            @linkClick="linkClick"
          >
          </c-table>
        </div>
        </template>
      </template>
    </c-card>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import VueDraggableResizable from 'vue-draggable-resizable'
import 'vue-draggable-resizable/dist/VueDraggableResizable.css'
export default {
  name: 'work-permit-graph',
  components: { VueDraggableResizable },
  data() {
    return {
      searchParam: {
        plantCd: null,
        permitYear: '',
        applicationDeptCd: null,
        issuedDeptCd: null,
        permitTypeCd: null,
        supplementWorkTypeCd: null,
        swpStepCd: null,
      },
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        isFull: true,
        top: '10px',
        param: {},
        closeCallback: null,
      },
      grid: {
        columns: [
           {
            // 작업개요
            name: 'workSummary',
            field: 'workSummary',
            label: 'LBL0000211',
            align: 'left',
            sortable: true,
            type: 'link',
            style: 'width:250px',
          },
          {
            // 진행단계
            name: 'swpStepName',
            field: 'swpStepName',
            label: 'LBLSTEPPROGRESS',
            align: 'center',
            sortable: true,
            style: 'width:100px',
          },
          {
            // 허가일
            name: 'permitDate',
            field: 'permitDate',
            label: 'LBL0000213',
            align: 'center',
            sortable: true,
            style: 'width:100px',
          },
          {
            // 허가서구분
            name: 'permitTypeName',
            field: 'permitTypeName',
            label: 'LBL0000204',
            align: 'center',
            sortable: true,
            style: 'width:100px',
          },
          {
            // 보충작업
            name: 'supplementWorkTypeNames',
            field: 'supplementWorkTypeNames',
            label: 'LBL0000205',
            align: 'left',
            sortable: true,
            style: 'width:200px',
          },
        ],
        data: [],
        height: '500px'
      },
      mapObj: {
        activeTask: 1,
        envUrl: '',
        processUrl: '',
        url: '',
        interval: {
          obj: null,
          seconds: 3,
          isPause: false,
        },
        process: {
          processCd: '',
          processName: '',
        },
        maps: [],
        slide: 0,
        date: '',
      },
      tempMapDetails: [],
      editable: true,
      supUrl: '',
      normalUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    mapRate() {
      return 0.835
    },
  },
  methods: {
    init() {
      //role setting
      this.editable = this.$route.meta.editable;
      //list setting
      this.normalUrl = selectConfig.sop.swp.workPermit.graph.normal.url
      this.supUrl = selectConfig.sop.swp.workPermit.graph.supplement.url;
      this.searchParam.permitYear = this.$comm.getThisYear();
      // 지도
      this.mapObj.url = selectConfig.main.workPermit.url

      this.getMapData();
    },
    
    getMapData() {
      let _height = 575 * this.mapRate
      this.$refs['carousel'].style.setProperty("--map-height", String(_height) + 'px');
      this.$http.url = this.mapObj.url;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        if (_result.data.length > 0) {
          this.mapObj.maps = _result.data;
          if (this.mapObj.maps && this.mapObj.maps.length > 0) {
            this.tempMapDetails = this.mapObj.maps[this.mapObj.slide].workPermitList;
          } else {
            this.tempMapDetails = [];
          }
          this.$_.forEach(this.mapObj.maps, map => {
            if (map.maps && map.maps.length > 0) {
              this.$_.forEach(map.maps, item => {
                this.$set(item, 'isHover', false)
              });
            }
            if (map.otherMaps && map.otherMaps.length > 0) {
              this.$_.forEach(map.otherMaps, item => {
                this.$set(item, 'isHover', false)
              });
            }
          })
          this.$refs['carousel'].style.setProperty("--map-ratio", this.mapObj.maps[0].ratio);
        }
      },);
    },
    transition() {   
      this.tempMapDetails = this.mapObj.maps[this.mapObj.slide].workPermitList;
    },
    /* eslint-disable no-unused-vars */
    getLeft(item) {
      /**
       * 지도의 크기는 800
       */
      let left = 0;
      let nonDetailLocation = item.locationXcoordinate + (item.resultTypes.length * 40)
      let detailLocation = item.locationXcoordinate + 250
      let calLocation = nonDetailLocation > detailLocation ? nonDetailLocation : detailLocation
      if (calLocation > 800) {
        left = 800 - calLocation
      }
      return left + 'px'
    },
    getTop(item) {
      /**
       * 지도의 높이는 594
       */
      let top = 30;
      let calLocation = item.locationYcoordinate + (item.resultTypes.length * 76)
      if (calLocation > 594) {
        top = 594 - calLocation
      }
      return !item.isHover ? '30px' : top + 'px'
    },
    workPermitIconName(resultType) {
      let icon = '';
      switch(resultType.sopWorkTypeCd) {
        case 'SPT0000001': // 일반
          icon = 'engineering';
          break;
        case 'SPT0000005': // 화기
          icon = 'local_fire_department';
          break;
        case 'SSWT000001': // 밀폐공간
          icon = 'dangerous';
          break;
        case 'SSWT000005': // 정전
          icon = 'flash_off';
          break;
        case 'SSWT000010': // 방사선
          icon = 'warning';
          break;
        case 'SSWT000015': // 굴착
          icon = 'hardware';
          break;
        case 'SSWT000020': // 고소
          icon = 'stairs';
          break;
        case 'SSWT000025': // 중장비
          icon = 'agriculture';
          break;
      }
      return icon;
    },
    workPermitbadgeColor(swpStepName) {
      let icon = '';
      switch(swpStepName) {
        case '승인완료':
          icon = 'light-blue';
          break;
        case '연장':
          icon = 'amber';
          break;
        case '작업완료':
          icon = 'light-green';
          break;
      }
      return icon;
    },
    openWorkPermit(item) {
      if (!item) return;
      this.popupOptions.isFull = true;
      this.popupOptions.title = this.$comm.getLangLabel('LBL0000214') //'작업허가서';
      this.popupOptions.param = {
        sopWorkPermitId: item.sopWorkPermitId,
        permitTypeCd: item.permitTypeCd,
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/swp/safeWorkPermitDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeWorkPermitPopup;
    },
    closeWorkPermitPopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
    linkClick(row, col, index) {
      // 작업허가서
      this.popupOptions.title = 'LBL0000214';
      this.popupOptions.param = {
        sopWorkPermitId: row ? row.sopWorkPermitId : '',
        permitTypeCd: row ? row.permitTypeCd : '',
      };
      this.popupOptions.target = () => import(`${'./safeWorkPermitDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeWorkPermitPopup;
    },

  }
};
</script>

<style lang="sass">
.markImage
  padding: 0px !important
  text-align: center
  font-size: 20px

.q-img__content
  .markImage.active
    border-width: 2px !important
    border-style: dashed !important
    border-color: hsla(0, 95%, 35%, 1) !important
.q-img__content > div
    background: rgba(0,0,0,0) 
// .vdr 
//   border: 3px dashed #000

.mainMarkImage
  padding: 0px !important
  text-align: center
  font-size: 20px
  // height: 30px !important
  position: static
  padding: 16px
  color: red
  // background: rgba(0,0,0,0)
  .markImage-abbreviation-parent
    // width:240px
    text-align: start
    position: absolute
    .markImage-abbreviation
      cursor: pointer
      display: inline-block
      // min-width:60px
      // max-width:250px
      width: auto
      border-left: 1px solid #757575
      border-right: 1px solid #757575
      border-top: 1px solid #757575
      border-bottom: 1px solid #757575
      border-radius: 5px
      .markImage-tran
        border-radius: 5px
      .markImage-abbreviation-banner
        border-right: 1px solid #757575
        display: inline-block
        text-align: center
        min-width:60px
        padding: 1px !important
        border-radius: 5px 0px 0px 5px
        i
          font-size: 20px
        div
          font-size: 12px !important
      .markImage-abbreviation-banner.last-banner
        border-right: 0
        border-radius: 0px 5px 5px 0px
      .markImage-abbreviation-plus
        display: inline-block
        text-align: center
        min-width:40px
      .markImage-abbreviation-banner-detail
        border-radius: 5px
        .q-banner__avatar i
          font-size: 20px !important
        .q-banner__content
          font-size: 12px !important
.markImage-abbreviation > div
  -webkit-animation: fadeInFromNone 0.1s ease-out
  -moz-animation: fadeInFromNone 0.1s ease-out
  -o-animation: fadeInFromNone 0.1s ease-out
  animation: fadeInFromNone 0.1s ease-out
  
.caption-work
  font-size: 0.5em
  font-style: italic
  white-space: normal
  color: #555

.caption-header
  font-size: 0.75em
  font-style: italic
  white-space: normal
  color: #555

.mark-list-enter, .mark-list-leave-to
  opacity: 0
  transform: translateY(5px)

.map-carousel
  height: var(--map-height) !important
  .q-carousel--padding, .q-carousel__slide
    padding: 0 !important
  .q-carousel__slide
    width: calc(var(--map-ratio) * var(--map-height)) !important
  .q-carousel--padding, .q-carousel__slide
    padding: 0 !important
  .q-carousel__arrow
    transition: all 0.5s
    opacity: 0.2
  .q-carousel__arrow:hover
    transition: all 0.5s
    opacity: 0.8
  .q-carousel__slides-container
    .q-panel.scroll
      overflow: hidden !important

</style>

<style scoped>

.mapTitleDiv {
  position: absolute;
  right: 5px;
  top: 5px;
  border-radius: 10px;
  background-color: #283046 !important;
  color: #fff;
  padding: 5px 10px;
  font-weight: 700;
  z-index: 2000;
  font-size: 12px;
}
</style>
